import { Component } from '@angular/core';
import { CONTACT_POSITIONS_ENDPOINT } from '../../models/ContactPosition';

@Component({
  selector: 'sebu-customer-contact-position-table',
  templateUrl: 'customer-contact-position-table.component.html',
  styles: [],
})
export class CustomerContactPositionTableComponent {

  endpoint = CONTACT_POSITIONS_ENDPOINT;

}
